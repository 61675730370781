Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#clients',
    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        filter: '',
        orderBy: 'name',
        sortBy: 'asc',

        view: 'index',

        // search model
        search: {
            location_id: 0,
            keywords: ''
        },

        // consultant model
        assign_consultant: {
            user_id: 0,
        },

        form: {
            name: '',
            email: '',
            phone: '',
            addr_1: '',
            addr_2: '',
            addr_3: '',
            addr_city: '',
            addr_postcode: '',
            location_id: 0,
            logo: '',
            color: ''
        },

        poolInfo: {
            candidate_id: 0,
            profile: '',
            photo: '',
            cv: '',
            availability: '',
            assignment: '',
            notes: '',
            active: 0,
            contract_type: 'PAYE'
        },

        contractTypeList: [
            { text: 'Select...', value: '' },
            { text: 'PAYE', value: 'PAYE' },
            { text: 'Umbrella', value: 'Umbrella' },
            { text: 'Limited', value: 'Limited' }
        ],

        newUser: {
            firstname: '',
            surname: '',
            email: '',
            role_id: 2,
            client_id: 0
        },

        clients: []
    },

    ready: function() {
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
    },

    methods: {
        route: function() {
            this.$set('loading.view', true);

            var hash = (window.location.hash.substr(1)).split('/');
            this.getLocationList();

            // index
            if(! hash[0]) {
                this.view = 'index';
                this.getClients();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getClient(hash[0]);
                return;
            }

            // add
            if(hash.length === 1 && hash[0] == 'add') {
                this.loading.view = false;
                this.view = 'form';
                $('.input-colorpicker').colorpicker({
                  format: 'hex',
                  color: '#46bfbd',
                }).on('changeColor', function(e) {
                    console.log(e.color.toHex());
                    this.form.color = e.color.toHex();
                    $('.navbar-default').attr('style', 'background-color:'+e.color.toHex());
                });
                if(typeof this.errors !== 'undefined') {
                    this.errors.client = null;
                }
            }

            // edit
            if(hash.length === 2 && hash[1] == 'edit') {
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.client = null;
                }
                if(this.clients.length) {
                    var client = this.clients.filter(function(client) {
                        return client.id == hash[0];
                    });

                    if(client.length) {
                        this.$set('form', client[0]);
                        this.$set('editing', JSON.parse(JSON.stringify(client[0])));
                        $('.input-colorpicker').colorpicker({
                            format: 'hex',
                            color: this.editing.color ? this.editing.color : '#46bfbd',
                        }).on('changeColor', function(e) {
                            console.log(e.color.toHex());
                            this.form.color = e.color.toHex();
                            $('.navbar-default').attr('style', 'background-color:'+e.color.toHex());
                        });
                    }

                    this.loading.view = false;
                }
                else {
                    this.$http.get(this.api_url+'/clients/'+hash[0], function(response) {
                        this.loading.view = false;
                        this.$set('form', response.client);
                        this.$set('editing', JSON.parse(JSON.stringify(response.client)));
                        console.log(this.editing.logo);
                        $('.input-colorpicker').colorpicker({
                            format: 'hex',
                            color: this.editing.color ? this.editing.color : '#46bfbd',
                        }).on('changeColor', function(e) {
                            console.log(e.color.toHex());
                            this.form.color = e.color.toHex();
                            $('.navbar-default').attr('style', 'background-color:'+e.color.toHex());
                        });
                    }).error(function (data, status, response) {
                        console.log(response);
                    });
                }
            }
        },

        // Add client user
        addUser: function(e) {
            e.preventDefault();
            // validate
            var user = this.newUser;
            this.$http.post(this.api_url+'/users', user, function(response) {
                this.users.push(response.user);
                this.newUser = { firstname: '', surname: '', email: '', role_id: 2, client_id: this.client.id }
                $('#adduserform').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.user', data.errors);
                }
            });
        },

        showUserForm: function() {
            this.newUser = {
                firstname: '',
                surname: '',
                email: '',
                role_id: 2,
                client_id: this.client.id
            };
        },

        deleteUser: function(user) {
            var conf = window.confirm('Are you sure you want to delete this user?');

            if(conf) {
                this.$http.delete(this.api_url+'/users/'+user.id, function(response) {
                    this.users.$remove(user);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // Get clients
        getClients: function() {
            this.$http.get(this.api_url+'/clients?per_page=10', function(response) {
                this.loading.view = false;
                this.$set('clients', response.clients);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get a client
        getClient: function(id) {

            // Get client info
            this.$http.get(this.api_url+'/clients/'+id, function(response) {
                this.loading.view = false;
                this.$set('client', response.client);
            }).error(function (data, status, response) {
                console.log(response);
            });

            // Get client pool
            this.$http.get(this.api_url+'/clients/'+id+'/pool?per_page=10', function(response) {
                this.$set('pool', response.pool);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pool_pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });

            // Get client users
            this.$http.get(this.api_url+'/users?client_id='+id, function(response) {
                this.$set('users', response.users);
            }).error(function (data, status, response) {
                console.log(response);
            });

            // Get client consultants
            this.$http.get(this.api_url+'/clients/'+id+'/consultants', function(response) {
                this.$set('consultants', response.consultants);
            }).error(function (data, status, response) {
                console.log(response);
            });

            // Get consultants
            this.$http.get(this.api_url+'/clients/'+id+'/not_consultants', function(response) {
                this.$set('availableConsultants', response.users);
                var consultantsList = [];
                consultantsList.push({ text: 'Select user', value: 0 });
                response.users.forEach(function(user) {
                    consultantsList.push({
                        text: user.name,
                        value: user.id
                    });
                });
                this.$set('consultantsList', consultantsList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        generateResetToken: function(user) {
            this.$http.post(this.api_url+'/users/'+user.id+'/reset-token', function(response) {
                user.reset_token = response.token
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Pagination for ClientPool
        pageClientPool: function(dir, e) {
            e.preventDefault();

            this.loading.pool = true; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pool_pagination.prev_page_url : this.pool_pagination.next_page_url;
            } else {
                var url = dir;
            }

            this.$http.get(url, function(response) {
                this.loading.pool = false;
                this.$set('pool', response.pool);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pool_pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Search client pool
        searchClientPool: function(e) {
            e.preventDefault();

            this.loading.pool = true; // show loading spinner

            var url = this.api_url+'/clients/'+this.client.id+'/pool?per_page=10';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.pool = false;
                this.$set('pool', response.pool);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pool_pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear client pool search
        clearClientPoolSearch: function() {
            this.loading.pool = true; // show loading spinner

            var url = this.api_url+'/clients/'+this.client.id+'/pool?per_page=10';

            this.$http.get(url, function(response) {
                this.loading.pool = false;
                this.$set('pool', response.pool);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pool_pagination', response.pagination);
                }
                this.search = {
                    location_id: 0,
                    keywords: ''
                };
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Delete a client
        deleteClient: function(client) {
            var conf = window.confirm('Are you sure you want to delete this client?');

            if(conf) {
                this.$http.delete(this.api_url+'/clients/'+client.id, function(response) {
                    this.clients.$remove(client);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        getLocationList: function() {
            this.$http.get(this.api_url+'/locations', function(response) {
                var locationList = [];
                locationList.push({ text: 'All locations', value: 0 });
                response.locations.forEach(function(location) {
                    locationList.push({
                        text: location.name,
                        value: location.id
                    });
                });
                this.$set('locationList', locationList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Pagination
        page: function(dir) {
            this.loading.view = true; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('clients', response.clients);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Sort
        sort: function(orderBy) {
            this.loading.view = true; // show loading spinner

            url = this.api_url+'/clients?per_page=10';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.sortBy = (this.orderBy == orderBy) ? 'desc' : 'asc';
            this.orderBy = orderBy;

            url += '&orderBy=' + orderBy + '&sortBy=' + this.sortBy;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('clients', response.clients);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Search clients
        searchClients: function(e) {
            e.preventDefault();

            this.loading.clients = true; // show loading spinner

            var url = this.api_url+'/clients?per_page=10';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.clients = false; // remove loading spinner
                this.clients = response.clients;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.loading.clients = true; // show loading spinner

            this.$http.get(this.api_url+'/clients?per_page=10', function(response) {
                this.loading.clients = false; // remove loading spinner
                this.$set('clients', response.clients);
                this.search = {
                    location_id: 0,
                    keywords: ''
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        submitForm: function(e) {
            e.preventDefault();

            var formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('phone', this.form.phone);
            formData.append('addr_1', this.form.addr_1);
            formData.append('addr_2', this.form.addr_2);
            formData.append('addr_3', this.form.addr_3);
            formData.append('addr_city', this.form.addr_city);
            formData.append('addr_postcode', this.form.addr_postcode);
            formData.append('location_id', this.form.location_id);
            formData.append('logo', this.$$.logoFileInput.files[0]);
            formData.append('color', this.form.color);

            if(typeof this.editing !== 'undefined') {

                this.$http.put(this.api_url+'/clients/'+this.editing.id, formData, function(response) {
                    this.form = {
                        name: '',
                        email: '',
                        phone: '',
                        addr_1: '',
                        addr_2: '',
                        addr_3: '',
                        addr_city: '',
                        addr_postcode: '',
                        location_id: 0,
                        logo: '',
                        color: ''
                    };
                    this.getClients();
                    this.loading.view = true;
                    this.view = 'index';
                    window.location = '#';
                }).error(function (data, status, response) {
                    if(status == 422) {
                         this.$set('errors.client', data.errors);
                    }
                });
                return;
            }

            this.$http.post(this.api_url+'/clients', formData, function(response) {
                this.form = {
                    name: '',
                    email: '',
                    phone: '',
                    addr_1: '',
                    addr_2: '',
                    addr_3: '',
                    addr_city: '',
                    addr_postcode: '',
                    location_id: 0,
                    logo: '',
                    color: ''
                };
                this.getClients();
                this.loading.view = true;
                this.view = 'index';
                window.location = '#';
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.client', data.errors);
                }
            });
        },

        showAvailableCandidates: function() {
            this.loading.available = true;
            this.$set('showAvailable', true);

            this.$http.get(this.api_url+'/clients/'+this.client.id+'/pool/available?per_page=10', function(response) {
                this.loading.available = false;
                this.$set('available', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('available_pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Pagination for AvailableCandidates
        pageAvailableCandidates: function(dir, e) {
            e.preventDefault();

            this.loading.available = true;
            this.$set('showAvailable', true);

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.available_pagination.prev_page_url : this.available_pagination.next_page_url;
            } else {
                var url = dir;
            }

            this.$http.get(url, function(response) {
                this.loading.available = false;
                this.$set('available', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('available_pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Search available candidates
        searchAvailableCandidates: function(e) {
            e.preventDefault();

            this.loading.available = true; // show loading spinner

            var url = this.api_url+'/clients/'+this.client.id+'/pool/available?per_page=10';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.available = false;
                this.$set('available', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('available_pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear AvailableCandidates search
        clearAvailableCandidatesSearch: function() {
            this.loading.available = true; // show loading spinner

            var url = this.api_url+'/clients/'+this.client.id+'/pool/available?per_page=10';

            this.$http.get(url, function(response) {
                this.loading.available = false;
                this.$set('available', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('available_pagination', response.pagination);
                }
                this.search = {
                    location_id: 0,
                    keywords: ''
                };
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        hideAvailableCandidates: function() {
            this.$set('showAvailable', false);
        },

        showPoolForm: function(candidate) {
            this.$set('editing_profile', null);
            this.$set('errors.assign', null);
            this.poolInfo = {
                candidate_id: 0,
                profile: '',
                photo: '',
                cv: '',
                availability: '',
                assignment: '',
                notes: '',
                active: 0,
                contract_type: 'PAYE'
            },
            this.$set('poolForm', true);
            this.$set('candidate', candidate);
        },

        hidePoolForm: function(e) {
            e.preventDefault();
            this.$set('poolForm', false);
            this.$set('candidate', []);
        },

        setPoolInfoCV: function(e) {

        },

        addCandidateToPool: function() {
            var formData = new FormData();
            formData.append('candidate_id', this.candidate.id);
            formData.append('profile', this.poolInfo.profile);
            formData.append('availability', this.poolInfo.availability);
            formData.append('photo', this.$$.photoInput.files[0]);
            formData.append('assignment', this.poolInfo.assignment);
            formData.append('notes', this.poolInfo.notes);
            formData.append('cv', this.$$.fileInput.files[0]);
            formData.append('active', this.poolInfo.active ? 'true' : 'false');
            formData.append('contract_type', this.poolInfo.contract_type);

            this.$http.post(this.api_url+'/clients/'+this.client.id+'/pool', formData, function (response) {
                this.candidate.profile = this.poolInfo.profile;
                this.candidate.availability = this.poolInfo.availability;
                this.candidate.photo = this.poolInfo.photo;
                this.candidate.assignment = this.poolInfo.assignment;
                this.candidate.notes = this.poolInfo.notes;
                this.candidate.cv = this.poolInfo.cv;
                this.candidate.active = this.poolInfo.active;
                this.available.$remove(this.candidate);
                this.pool.push(this.candidate);
                this.showAvailable = false;
                this.candidate = null;
                this.poolForm = false;
                this.poolInfo = {
                    candidate_id: 0,
                    profile: '',
                    photo: '',
                    cv: '',
                    availability: '',
                    assignment: '',
                    notes: '',
                    active: 0,
                    contract_type: 'PAYE'
                }
                $('#assigncandidate').modal('hide');
            }).error(function (data, status, request) {
                if(status == 422) {
                     this.$set('errors.assign', data.errors);
                }
                console.log(request);
            });
        },

        editCandidatePoolProfile: function(candidate) {
            this.$set('errors.assign', null);
            this.$set('poolForm', true);
            this.$set('editing_profile', candidate); //JSON.parse(JSON.stringify(candidate))
            this.poolInfo = {
                candidate_id: candidate.id,
                profile: candidate.profile,
                photo: candidate.photo,
                cv: candidate.cv,
                availability: candidate.availability,
                assignment: candidate.assignment,
                notes: candidate.notes,
                active: candidate.active,
                contract_type: candidate.contract_type
            }
        },

        updateCandidateProfile: function() {
            var formData = new FormData();

            formData.append('candidate_id', this.editing_profile.id);
            formData.append('profile', this.poolInfo.profile);
            formData.append('availability', this.poolInfo.availability);
            formData.append('photo', this.$$.photoInput.files[0]);
            formData.append('assignment', this.poolInfo.assignment);
            formData.append('notes', this.poolInfo.notes);
            formData.append('cv', this.$$.fileInput.files[0]);
            formData.append('active', this.poolInfo.active ? 'true' : 'false');
            formData.append('contract_type', this.poolInfo.contract_type);

            this.$http.post(this.api_url+'/clients/'+this.client.id+'/pool/'+this.editing_profile.id, formData, function (response) {
                this.candidate = null;
                this.poolForm = false;
                this.getClient(this.client.id);
                this.poolInfo = {
                    candidate_id: 0,
                    profile: '',
                    photo: '',
                    cv: '',
                    availability: '',
                    assignment: '',
                    notes: '',
                    active: 0,
                    contract_type: 'PAYE'
                }
                $('#assigncandidate').modal('hide');
            }).error(function (data, status, request) {
                if(status == 422) {
                     this.$set('errors.assign', data.errors);
                }
                console.log(request);
            });
        },

        submitPoolForm: function(e) {
            e.preventDefault();
            if(typeof this.editing_profile !== 'undefined' && this.editing_profile !== null) {
                this.updateCandidateProfile();
            }
            else {
                this.addCandidateToPool();
            }
        },

        toggleActiveState: function(candidate) {
            this.$set('updatingActiveState', true);
            this.$http.put(this.api_url+'/clients/'+this.client.id+'/pool/'+candidate.id+'/toggle-active', function(response) {
                this.updatingActiveState = false;
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        assignConsultant: function(e) {
           e.preventDefault();

           var formData = new FormData();

           formData.append('user_id', this.assign_consultant.user_id);

           this.$http.post(this.api_url+'/clients/'+this.client.id+'/consultants', formData, function (response) {
               this.consultants.push(response.consultant);
               this.consultantsList.$remove(response.consultant.id);
               this.assign_consultant = {
                   user_id: 0,
               }
               // Get consultants
               this.$http.get(this.api_url+'/clients/'+this.client.id+'/not_consultants', function(response) {
                   this.$set('availableConsultants', response.users);
                   var consultantsList = [];
                   consultantsList.push({ text: 'Select user', value: 0 });
                   response.users.forEach(function(user) {
                       consultantsList.push({
                           text: user.name,
                           value: user.id
                       });
                   });
                   this.$set('consultantsList', consultantsList);
               }).error(function (data, status, response) {
                   console.log(response);
               });
           }).error(function (data, status, request) {
               console.log(request);
           });
        },

        sendAccountSetupInstructions: function(user, e) {
            e.preventDefault();
            this.$http.post(this.api_url+'/users/'+user.id+'/setup', function(response) {

            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        removeCandidateFromPool: function(candidate) {
            var conf = window.confirm('Are you sure you want to remove this candidate?');

            if(conf) {
                this.$http.delete(this.api_url+'/clients/'+this.client.id+'/pool/'+candidate.id, function(response) {
                    this.pool.$remove(candidate);
                    if(typeof availableCandidates !== 'undefined') {
                        this.availableCandidates.push(candidate);
                    }
                }.bind(this));
            }
        },

        removeUserFromClient: function(consultant) {
            var conf = window.confirm('Are you sure you want to remove this user and stop them receiving client/candidate notifications?');

            if(conf) {
                this.$http.delete(this.api_url+'/clients/'+this.client.id+'/consultants/'+consultant.id, function(response) {
                    this.consultants.$remove(consultant);
                    // Get consultants
                    this.$http.get(this.api_url+'/clients/'+this.client.id+'/not_consultants', function(response) {
                        this.$set('availableConsultants', response.users);
                        var consultantsList = [];
                        consultantsList.push({ text: 'Select user', value: 0 });
                        response.users.forEach(function(user) {
                            consultantsList.push({
                                text: user.name,
                                value: user.id
                            });
                        });
                        this.$set('consultantsList', consultantsList);
                    }).error(function (data, status, response) {
                        console.log(response);
                    });
                });
            }
        },
    }
});
