Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({
    el: '#reports',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        search: {
            from: '',
            to: ''
        }
    },

    ready: function() {
        this.filterCharts();

        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.daysOfWeekDisabled = [1,2,3,4,5,6];
        $.fn.datepicker.defaults.daysOfWeekHighlighted = [0];
        $.fn.datepicker.defaults.startView = 'month';
        $.fn.datepicker.defaults.weekStart = 1;

        // $.fn.datepicker.defaults.weekStart.on('changeDate', function(e) {

        // });
    },

    methods: {


        filterCharts: function() {
            var ctx = document.getElementById('timesheet-totals').getContext('2d');

            var url = this.api_url+'/reports/totals?';

            if(this.search.from) url += '&from='+this.search.from;
            if(this.search.to) url += '&to='+this.search.to;

            this.$http.get(url, function(response) {

                if(this.totalsChart) this.totalsChart.destroy();

                var ctx = document.getElementById('timesheet-totals').getContext('2d');

                var labels = [];
                var totals = [];

                response.data.forEach(function(set) {
                    labels.push(set.label);
                    totals.push(set.value);
                });

                var data = {
                    labels: labels,
                    datasets: [
                        {
                            label: "Week starting",
                            fillColor: "rgba(117, 184, 84, 0.5)",
                            strokeColor: "#75B854",
                            pointColor: "#75B854",
                            pointStrokeColor: "rgba(117, 184, 84, 0.5)",
                            data: totals
                        }
                    ]
                };

                var chartData = new Chart(ctx).Line(data, {
                    scaleFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipFontSize: 12,
                    scaleFontColor: "#333",
                    scaleFontStyle: "bold",
                    scaleFontSize: 10,
                    responsive: true,
                    maintainAspectRatio: true,
                    bezierCurveTension : 0,
                    scaleGridLineColor : "#CCC",
                    scaleLineColor : "#CCC",
                    scaleShowLabels: true,
                    datasetStrokeWidth: 1,
                    pointDotStrokeWidth: 2,
                    pointDotRadius : 3,
                    tooltipFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipTitleFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                });

                this.$set('totalsChart', chartData);

            });



            var ctx = document.getElementById('timesheet-totals').getContext('2d');

            var url = this.api_url+'/reports/types?';

            if(this.search.from) url += '&from='+this.search.from;
            if(this.search.to) url += '&to='+this.search.to;

            this.$http.get(url, function(response) {

                if(this.typesChart) this.typesChart.destroy();
                $('#timesheet-types').parent().parent().children('ul.pie-legend').remove();

                var ctx = document.getElementById('timesheet-types').getContext('2d');

                var data = [];
                var colours = ['#46BFBD', '#FDB45C', '#F7464A'];

                var i = 0;
                response.data.forEach(function(set) {
                    data.push({
                        value: set.value,
                        highlight: '#555555',
                        color: colours[i],
                        label: set.label
                    });
                    i++;
                });

                var scanTypeBreakdown = new Chart(ctx).Pie(data, {
                    responsive: true,
                    maintainAspectRatio: true,
                    legendTemplate : '<ul class=\"<%=name.toLowerCase()%>-legend\">' +
                        '<% for (var i=0; i<segments.length; i++){%><li>' +
                            '<span style=\"background-color:<%=segments[i].fillColor%>\"></span>' +
                            '<%if(segments[i].label){%><%=segments[i].label%> <strong><%=segments[i].value%></strong><%}%>' +
                        '</li><%}%>' +
                    '</ul>'
                });

                $('#timesheet-types').parent().parent().append(scanTypeBreakdown.generateLegend());
                this.$set('typesChart', scanTypeBreakdown);

            });






        }
    }
});
