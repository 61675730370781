Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#users',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        users: [],

        pagination: [],

        newUser: {
            firstname: '',
            surname: '',
            email: '',
            role_ids: [1],
            client_id: 0
        },

        rolesList: [],
        searchRolesList: [],

        clientsList: [],

        search: {
            role_id: 0,
            client_id: 0,
            keywords: ''
        },

        loaded: false
    },

    ready: function() {
        this.getUsers();
        this.getRolesList();
        this.getClientsList();

        var hash = (window.location.hash.substr(1)).split('/');

        if(hash[0] == 'addclient') {
            this.newUser.role_ids = [2];
            this.newUser.client_id = hash[1];
            this.getClient(hash[1]);
            $('#form').modal('show');
        }
    },



    methods: {
        getUsers: function() {
            this.$http.get(this.api_url+'/users?per_page=10', function(response) {
                this.users = response.users;
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
                this.loaded = true;
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        getRolesList: function() {
            this.$http.get(this.api_url+'/roles', function(response) {
                var self = this;
                self.searchRolesList.push({ text: 'All roles', value: 0 });
                self.rolesList.push({ text: 'Select', value: 0 });
                response.roles.forEach(function(role) {
                    self.searchRolesList.push({
                        text: role.name,
                        value: role.id
                    });
                    if(role.id != 2) {
                        self.rolesList.push({
                            text: role.name,
                            value: role.id
                        });
                    }
                });
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        getClientsList: function() {
            this.$http.get(this.api_url+'/clients', function(response) {
                var self = this;
                self.clientsList.push({ text: 'All clients', value: 0 });
                response.clients.forEach(function(client) {
                    self.clientsList.push({
                        text: client.name,
                        value: client.id
                    });
                });
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Pagination
        page: function(dir) {
            this.leaded = false; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;

            if(parseInt(this.search.role_id)) url += '&role_id='+this.search.role_id;
            if(parseInt(this.search.expertise_id)) url += '&client='+this.search.client;
            if(this.search.keywords) url += '&search='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loaded = true; // remove loading spinner
                this.$set('users', response.users);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        page: function(dir) {

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            //url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;

            if(parseInt(this.search.role_id)) url += '&role_id='+this.search.role_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.$set('users', response.users);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        addUser: function(e) {
            e.preventDefault();
            // validate
            var user = this.newUser;
            this.$http.post(this.api_url+'/users', user, function(response) {
                this.users.push(response.user);
                this.newUser = { firstname: '', surname: '', email: '', role_ids: [1], client_id: 0 }
                $('#form').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.user', data.errors);
                }
            });
        },

        sendAccountSetupInstructions: function(user, e) {
            e.preventDefault();
            this.$http.post(this.api_url+'/users/'+user.id+'/setup', function(response) {
                //
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        deleteUser: function(user, e) {
            e.preventDefault();
            var conf = window.confirm('Are you sure you want to delete this user?');

            if(conf) {
                this.$http.delete(this.api_url+'/users/'+user.id, function(response) {
                    this.users.$remove(user);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        searchUsers: function(e) {
            e.preventDefault();
            var url = this.api_url+'/users?per_page=10';

            if(parseInt(this.search.role_id)) url += '&role_id='+this.search.role_id;
            if(parseInt(this.search.role_id) == 2 && parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.users = response.users;
                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        getClient: function(id) {
            this.$http.get(this.api_url+'/clients/'+id, function(response) {
                this.$set('client', response.client);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        showForm: function() {
            if(typeof this.client !== 'undefined') {
                this.client = null;
            }

            this.newUser = {
                firstname: '',
                surname: '',
                email: '',
                role_ids: [1],
                client_id: 0
            };
        }
    }

});
