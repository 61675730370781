Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#expertise',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        expertise: [],

        pagination: [],

        newExpertise: {
            name: ''
        },

        // search: {
        //     role_id: 0,
        //     client_id: 0,
        //     keywords: ''
        // },

        loaded: false
    },

    ready: function() {
        this.getExpertise();

        var hash = (window.location.hash.substr(1)).split('/');
    },



    methods: {
        getExpertise: function() {
            this.$http.get(this.api_url+'/expertise?per_page=10', function(response) {
                this.expertise = response.expertise;
                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
                this.loaded = true;
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        prevPage: function() {
            this.$http.get(this.pagination.prev_page_url, function(response) {
                this.expertise = response.expertise;

                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        nextPage: function() {
            this.$http.get(this.pagination.next_page_url, function(response) {
                this.expertise = response.expertise;

                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        submitForm: function(e) {
            e.preventDefault();

            if(this.editingExpertise !== null) {
                // editing
                var expertise = this.newExpertise;
                this.$http.put(this.api_url+'/expertise/'+this.editingExpertise.id, expertise, function(response) {
                    //this.expertise.push(response.expertise);
                    this.editingExpertise.name = this.newExpertise.name;
                    this.newExpertise = { name: '' }
                    $('#form').modal('hide');
                }).error(function (data, status, response) {
                    if(status == 422) {
                         this.$set('errors.expertise', data.errors);
                    }
                });

                return;
            }

            // adding
            var expertise = this.newExpertise;
            this.$http.post(this.api_url+'/expertise', expertise, function(response) {
                this.expertise.push(response.expertise);
                this.newExpertise = { name: '' }
                $('#form').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.expertise', data.errors);
                }
            });
        },

        deleteExpertise: function(expertise) {
            var conf = window.confirm('Are you sure you want to delete this expertise?');

            if(conf) {
                this.$http.delete(this.api_url+'/expertise/'+expertise.id, function(response) {
                    this.expertise.$remove(expertise);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // searchUsers: function(e) {
        //     e.preventDefault();
        //     var url = this.api_url+'/users?per_page=10';

        //     if(parseInt(this.search.role_id)) url += '&role_id='+this.search.role_id;
        //     if(parseInt(this.search.role_id) == 2 && parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
        //     if(this.search.keywords) url += '&search='+this.search.keywords;

        //     this.$http.get(url, function(response) {
        //         this.users = response.users;
        //         if(typeof response.metadata !== 'undefined') {
        //             this.pagination = response.metadata;
        //         }
        //     }).error(function (data, status, response) {
        //         console.log(response);
        //     });
        // },

        // showForm: function() {

        //     this.newExpertise = {
        //         name: ''
        //     };
        // }

        showForm: function(expertise, e) {
            if(typeof e !== 'undefined') {
                e.preventDefault();
            }

            this.$set('errors.expertise', null);

            if(typeof expertise.id !== 'undefined') {
                this.$set('editingExpertise', expertise);
                this.newExpertise = {
                    name: expertise.name
                };
            } else {
                this.$set('editingExpertise', null);
                this.newExpertise = {
                    name: ''
                };
            }
        }
    }

});
