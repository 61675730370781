Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#industries',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        industries: [],

        pagination: [],

        newIndustry: {
            name: ''
        },

        // search: {
        //     role_id: 0,
        //     client_id: 0,
        //     keywords: ''
        // },

        loaded: false
    },

    ready: function() {
        this.getIndustries();

        var hash = (window.location.hash.substr(1)).split('/');
    },



    methods: {
        getIndustries: function() {
            this.$http.get(this.api_url+'/industries?per_page=10', function(response) {
                this.industries = response.industries;
                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
                this.loaded = true;
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        prevPage: function() {
            this.$http.get(this.pagination.prev_page_url, function(response) {
                this.industries = response.industries;

                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        nextPage: function() {
            this.$http.get(this.pagination.next_page_url, function(response) {
                this.industries = response.industries;

                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        submitForm: function(e) {
            e.preventDefault();

            if(this.editingIndustry !== null) {
                // editing
                var industry = this.newIndustry;
                this.$http.put(this.api_url+'/industries/'+this.editingIndustry.id, industry, function(response) {
                    //this.industry.push(response.expertise);
                    this.editingIndustry.name = this.newIndustry.name;
                    this.newIndustry = { name: '' }
                    $('#form').modal('hide');
                }).error(function (data, status, response) {
                    if(status == 422) {
                         this.$set('errors.industry', data.errors);
                    }
                });

                return;
            }

            // adding
            var industry = this.newIndustry;
            this.$http.post(this.api_url+'/industries', industry, function(response) {
                this.industries.push(response.industry);
                this.newIndustry = { name: '' }
                $('#form').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.industry', data.errors);
                }
            });
        },

        deleteIndustry: function(industry) {
            var conf = window.confirm('Are you sure you want to delete this industry?');

            if(conf) {
                this.$http.delete(this.api_url+'/industries/'+industry.id, function(response) {
                    this.industries.$remove(industry);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // searchUsers: function(e) {
        //     e.preventDefault();
        //     var url = this.api_url+'/users?per_page=10';

        //     if(parseInt(this.search.role_id)) url += '&role_id='+this.search.role_id;
        //     if(parseInt(this.search.role_id) == 2 && parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
        //     if(this.search.keywords) url += '&search='+this.search.keywords;

        //     this.$http.get(url, function(response) {
        //         this.users = response.users;
        //         if(typeof response.metadata !== 'undefined') {
        //             this.pagination = response.metadata;
        //         }
        //     }).error(function (data, status, response) {
        //         console.log(response);
        //     });
        // },

        showForm: function(industry, e) {
            if(typeof e !== 'undefined') {
                e.preventDefault();
            }

            this.$set('errors.industry', null);

            if(typeof industry.id !== 'undefined') {
                this.$set('editingIndustry', industry);
                this.newIndustry = {
                    name: industry.name
                };
            } else {
                this.$set('editingIndustry', null);
                this.newIndustry = {
                    name: ''
                };
            }
        }
    }

});
