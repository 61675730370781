Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#candidates',
    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        filter: '',
        orderBy: 'surname',
        sortBy: 'asc',
        view: 'list',

        // search model
        search: {
            expertise_id: 0,
            industry_id: 0,
            location_id: 0,
            keywords: ''
        }
    },

    ready: function() {
        var hash = (window.location.hash.substr(1)).split('/');
        // show
        if(hash.length === 1 && hash[0]) {
            this.view = 'show';
            this.showCandidateFromUrl(hash[0]);
            return;
        }

        this.getCandidates();
        this.getExpertiseList();
        this.getIndustryList();
        this.getLocationList();
    },

    methods: {
        // Get candidates
        getCandidates: function() {
            this.$set('loading.candidates', true); // show loading spinner

            this.$http.get(this.api_url+'/candidates?per_page=10', function(response) {
                this.loading.candidates = false; // remove loading spinner
                this.$set('candidates', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get expertise for select boxes
        getExpertiseList: function() {
            this.$http.get(this.api_url+'/expertise', function(response) {
                var expertiseList = [];
                expertiseList.push({ text: 'Select areas of expertise', value: 0 });
                response.expertise.forEach(function(expertise) {
                    expertiseList.push({
                        text: expertise.name,
                        value: expertise.id
                    });
                });
                this.$set('expertiseList', expertiseList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get industries for select boxes
        getIndustryList: function() {
            this.$http.get(this.api_url+'/industries', function(response) {
                var industryList = [];
                industryList.push({ text: 'Select industry experience', value: 0 });
                response.industries.forEach(function(industry) {
                    industryList.push({
                        text: industry.name,
                        value: industry.id
                    });
                });
                this.$set('industryList', industryList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get industries for select boxes
        getLocationList: function() {
            this.$http.get(this.api_url+'/locations', function(response) {
                var locationList = [];
                locationList.push({ text: 'Select locations', value: 0 });
                response.locations.forEach(function(location) {
                    locationList.push({
                        text: location.name,
                        value: location.id
                    });
                });
                this.$set('locationList', locationList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Pagination
        page: function(dir) {
            this.$set('loading.candidates', true); // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(parseInt(this.search.expertise_id)) url += '&expertise_id='+this.search.expertise_id;
            if(parseInt(this.search.industry_id)) url += '&industry_id='+this.search.industry_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.candidates = false; // remove loading spinner
                this.$set('candidates', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Sort
        sort: function(orderBy) {
            this.$set('loading.candidates', true); // show loading spinner

            url = this.api_url+'/candidates?per_page=10';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(parseInt(this.search.expertise_id)) url += '&expertise_id='+this.search.expertise_id;
            if(parseInt(this.search.industry_id)) url += '&industry_id='+this.search.industry_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.sortBy = (this.orderBy == orderBy) ? 'desc' : 'asc';
            this.orderBy = orderBy;

            url += '&orderBy=' + orderBy + '&sortBy=' + this.sortBy;

            this.$http.get(url, function(response) {
                this.loading.candidates = false; // remove loading spinner
                this.$set('candidates', response.candidates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Search candidates
        searchCandidates: function(e) {
            e.preventDefault();

            this.loading.candidates = true; // show loading spinner

            var url = this.api_url+'/candidates?per_page=10';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(parseInt(this.search.expertise_id)) url += '&expertise_id='+this.search.expertise_id;
            if(parseInt(this.search.industry_id)) url += '&industry_id='+this.search.industry_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.candidates = false; // remove loading spinner
                this.candidates = response.candidates;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.loading.candidates = true; // show loading spinner

            this.$http.get(this.api_url+'/candidates?per_page=10', function(response) {
                this.loading.candidates = false; // remove loading spinner
                this.$set('candidates', response.candidates);
                this.search = {
                    expertise_id: 0,
                    industry_id: 0,
                    location_id: 0,
                    keywords: ''
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Show a candidate's details
        showCandidate: function(candidate, e) {
            e.preventDefault();
            
            this.$http.get(this.api_url+'/candidates/'+candidate.id, function(response) {
                this.$set('candidate', response.candidate);
            }).error(function (data, status, request) {
                console.log(request);
            });
            
            this.$http.get(this.api_url+'/candidates/'+candidate.id+'/clients', function(response) {
                this.$set('clients', response.clients);
            }).error(function (data, status, request) {
                console.log(request);
            });

            this.$http.get(this.api_url+'/candidates/'+candidate.id+'/timesheets', function(response) {
                this.$set('timesheets', response.timesheets);
            }).error(function (data, status, request) {
                console.log(request);
            });

            // this.$set('candidate', candidate);
            this.view = 'show';
        },

        generateResetToken: function(candidate) {
            this.$http.post(this.api_url+'/candidates/'+candidate.id+'/reset-token', function(response) {
                candidate.reset_token = response.token
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Show a candidate's details from URL
        showCandidateFromUrl: function(candidate_id) {
            this.$set('loading.candidate', true); // show loading spinner
            this.$http.get(this.api_url+'/candidates/'+candidate_id, function(response) {
                this.$set('candidate', response.candidate);
                this.loading.candidate = false;
            }).error(function (data, status, request) {
                console.log(request);
            });
            
            this.$http.get(this.api_url+'/candidates/'+candidate_id+'/clients', function(response) {
                this.$set('clients', response.clients);
            }).error(function (data, status, request) {
                console.log(request);
            });

            this.$http.get(this.api_url+'/candidates/'+candidate_id+'/timesheets', function(response) {
                this.$set('timesheets', response.timesheets);
            }).error(function (data, status, request) {
                console.log(request);
            });
            
            this.view = 'show';
        },

        // Show add/edit form
        showForm: function(candidate, e) {
            if(typeof e !== 'undefined') {
                e.preventDefault();
            }

            if(typeof candidate.id !== 'undefined') {
                // Editing an existing candidate
                this.$set('editingCandidate', candidate);
                this.$set('form', candidate);
            }

            this.$set('errors.candidate', null);

            this.view = 'form';
        },

        // Submit add/edit form
        submitForm: function(e) {
            e.preventDefault();
            if(typeof this.editingCandidate !== 'undefined') {
                // Updating candidate
                this.$http.put(this.api_url+'/candidates/'+this.editingCandidate.id, this.form, function(response) {
                    this.form = null;
                    
                    var filtered = this.candidates.filter(function (candidate) {
                        return candidate.id == this.editingCandidate.id;
                    }.bind(this))[0];

                    this.candidates.$remove(this.editingCandidate);
                    this.candidates.push(response.candidate);

                    this.editingCandidate = null;
                    this.view = 'list';
                }).error(function (data, status, request) {
                    if(status == 422) {
                         this.$set('errors.candidate', data.errors);
                    }
                });

                return;
            }

            this.$http.post(this.api_url+'/candidates', this.form, function(response) {
                this.candidates.push(response.candidate);
                this.form = null;
                this.view = 'list';
            }).error(function (data, status, request) {
                if(status == 422) {
                     this.$set('errors.candidate', data.errors);
                }
            });
        },

        sendAccountSetupInstructions: function(candidate, e) {
            e.preventDefault();
            this.$http.post(this.api_url+'/candidates/'+candidate.id+'/setup', function(response) {
                candidate.has_credentials = true;
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Delete candidate
        deleteCandidate: function(candidate, e) {
            e.preventDefault();

            var conf = window.confirm('Are you sure you want to delete this candidate?');

            if(conf) {
                this.$http.delete(this.api_url+'/candidates/'+candidate.id, function(response) {
                    this.candidates.$remove(candidate);
                }).error(function (data, status, request) {
                    console.log(request);
                });
            }
        },
    }
});
