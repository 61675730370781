Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#locations',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        locations: [],

        pagination: [],

        newLocation: {
            name: ''
        },

        // search: {
        //     role_id: 0,
        //     client_id: 0,
        //     keywords: ''
        // },

        loaded: false
    },

    ready: function() {
        this.getLocations();

        var hash = (window.location.hash.substr(1)).split('/');
    },



    methods: {
        getLocations: function() {
            this.$http.get(this.api_url+'/locations?per_page=10', function(response) {
                this.locations = response.locations;
                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
                this.loaded = true;
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        prevPage: function() {
            this.$http.get(this.pagination.prev_page_url, function(response) {
                this.locations = response.locations;

                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        nextPage: function() {
            this.$http.get(this.pagination.next_page_url, function(response) {
                this.locations = response.locations;

                if(typeof response.metadata !== 'undefined') {
                    this.pagination = response.metadata;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        submitForm: function(e) {
            e.preventDefault();

            if(this.editingLocation !== null) {
                // editing
                var location = this.newLocation;
                this.$http.put(this.api_url+'/locations/'+this.editingLocation.id, location, function(response) {
                    //this.location.push(response.expertise);
                    this.editingLocation.name = this.newLocation.name;
                    this.newLocation = { name: '' }
                    $('#form').modal('hide');
                }).error(function (data, status, response) {
                    if(status == 422) {
                         this.$set('errors.location', data.errors);
                    }
                });

                return;
            }

            // adding
            var location = this.newLocation;
            this.$http.post(this.api_url+'/locations', location, function(response) {
                this.locations.push(response.location);
                this.newLocation = { name: '' }
                $('#form').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.location', data.errors);
                }
            });
        },

        deleteLocation: function(location) {
            var conf = window.confirm('Are you sure you want to delete this location?');

            if(conf) {
                this.$http.delete(this.api_url+'/locations/'+location.id, function(response) {
                    this.locations.$remove(location);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // searchUsers: function(e) {
        //     e.preventDefault();
        //     var url = this.api_url+'/users?per_page=10';

        //     if(parseInt(this.search.role_id)) url += '&role_id='+this.search.role_id;
        //     if(parseInt(this.search.role_id) == 2 && parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
        //     if(this.search.keywords) url += '&search='+this.search.keywords;

        //     this.$http.get(url, function(response) {
        //         this.users = response.users;
        //         if(typeof response.metadata !== 'undefined') {
        //             this.pagination = response.metadata;
        //         }
        //     }).error(function (data, status, response) {
        //         console.log(response);
        //     });
        // },

        showForm: function(location, e) {
            if(typeof e !== 'undefined') {
                e.preventDefault();
            }

            this.$set('errors.location', null);

            if(typeof location.id !== 'undefined') {
                this.$set('editingLocation', location);
                this.newLocation = {
                    name: location.name
                };
            } else {
                this.$set('editingLocation', null);
                this.newLocation = {
                    name: ''
                };
            }
        }
    }

});
